<template>
  <el-card v-loading="loading">
    <h4 style="margin-top: 0">Общие настройки</h4>

    <el-form
      ref="procedureUpdateForm"
      style="width: 600px"
      size="small"
      label-position="top"
      :model="procedure"
      :rules="rules"
    >
      <el-form-item label="Название" prop="name">
        <el-input
          v-model="procedure.name"
          maxlength="255"
          show-word-limit
          @change="updateProcedure"
        ></el-input>
      </el-form-item>

      <el-form-item label="Тип процедуры" prop="type">

        <el-select disabled v-model="procedure.type" style="width:100%">
          <el-option
            v-for="(typeName, typeId) in $companyConfiguration.settings.getEnum('compensation_procedure_types')"
            :key="typeId"
            :label="typeName"
            :value="typeId"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Год оценки эффективности" prop="year">
        <el-date-picker
          :value="procedure.year.toString()"
          disabled
          style="width:100%"
          type="year"
          value-format="yyyy"
        >
        </el-date-picker>

      </el-form-item>

      <el-form-item label="Дата выгрузки данных" prop="data_export_date">
        <el-date-picker
          v-model="procedure.data_export_date"
          disabled
          style="width:100%"
          placeholder="Выберите дату"
          :picker-options="{firstDayOfWeek: 1}"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd.MM.yyyy"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="Даты проведения" prop="dates">
        <div style="display: flex; justify-content: space-between">
          <el-date-picker
            v-model="procedure.date_start"
            style="width:270px"
            placeholder="Выберите дату начала"
            :picker-options="{firstDayOfWeek: 1}"
            type="date"
            :clearable="false"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            @change="updateProcedure"
          >
          </el-date-picker>
          -
          <el-date-picker
            v-model="procedure.date_end"
            style="width:270px"
            :clearable="false"
            placeholder="Выберите дату окончания"
            :picker-options="{firstDayOfWeek: 1}"
            type="date"
            value-format="yyyy-MM-dd"
            format="dd.MM.yyyy"
            @change="updateProcedure"
          >
          </el-date-picker>
        </div>
      </el-form-item>


    </el-form>
  </el-card>
</template>

<script>

import requestSender from "@/api/base/requestSender";

export default {
  name: "CompensationProcedureEditBaseSettings",
  components: {},
  props:{
    procedure: {type: Object, required: true},
  },


  data() {

    return {
      loading: false,

      rules: {
        name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        data_export_date: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },

    }
  },

  watch: {},
  methods: {
    updateProcedure() {
      this.$refs.procedureUpdateForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;
        requestSender('post', 'compensation-procedure/update', {
          ...this.procedure,
        })
          .then(() => {
          })
          .finally(() => {
            this.loading = false;
          });

      });

    },
  },



}
</script>

<style>

</style>